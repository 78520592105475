/* Add this to your global CSS or relevant stylesheet */

.blink-border {
  animation: borderBlink 1.5s linear infinite;
}

@keyframes borderBlink {
  0%,
  100% {
    border-color: #2136d4;
    box-shadow: 0 0 0 0 #2136d4;
  }
  50% {
    border-color: #2136d4;
    box-shadow: 0 0 10px 2px #2136d4;
  }
}
