.marker-red-pulse {
  animation: 1.5s marker-red-pulse-animation infinite ease-in-out;
}

@keyframes marker-red-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #ff0000;
  }

  100% {
    box-shadow: 0 0 0 25px #ff000000;
  }
}

.marker-orange-pulse {
  animation: 1.5s marker-orange-pulse-animation infinite ease-in-out;
}

@keyframes marker-orange-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #ff9900;
  }

  100% {
    box-shadow: 0 0 0 25px #ff990000;
  }
}
.marker-yellow-pulse {
  animation: 1.5s marker-yellow-pulse-animation infinite ease-in-out;
}

@keyframes marker-yellow-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #fbff00;
  }

  100% {
    box-shadow: 0 0 0 25px #fbff0000;
  }
}
.marker-blue-pulse {
  animation: 1.5s marker-blue-pulse-animation infinite ease-in-out;
}

@keyframes marker-blue-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #2136d4;
  }

  100% {
    box-shadow: 0 0 0 25px #2136d400;
  }
}
